import React from 'react';
import { imgBrand, alt } from '../../lib/brand';
import GetStartedCinemaTop from '../cinemagraphs/GetStartedCinemaTop';
import IntroBlock from '../IntroBlock/';
import styles from './styles.module.less';

var refinanceImg = imgBrand('typesImg/Icons_Refinance_small.png');
var homeLoanImg = imgBrand('typesImg/Icons_homeloans_active_small.png');
var secondLoanImg = imgBrand('typesImg/Icons_SecondHome_small.png');
var reverseImg = imgBrand('typesImg/Icons_ReverseMortgage_small_Active copy.png');

const CurrentHOPanel = props => {
   const header = (
      <span>
         You’ve done it once. <br className={styles.mobileHide} />
         Let us help you do it again.
      </span>
   );
   const par =
      'As a current homeowner, there are a lot of things you need to know when it comes to your current house and the loan that goes with it. Have no fear. We’re here to help you navigate the unknown.';

   return (
      <div className={styles.CurrentHOPanelDiv}>
         <GetStartedCinemaTop />
         <IntroBlock header={header} par={par} />
         <section className={styles.CurrentHOPanel}>
            <div className={styles.subcontainer}>
               <a href="#refinance">
                  <div className={styles.product}>
                     <div className={styles.icon}>
                        <img src={refinanceImg} alt={alt('Refinance Your Mortgage - Refinance Icon')} />
                     </div>
                     <h1 className={styles.caption}>
                        Refinance
                        <br />
                     </h1>
                  </div>
               </a>
               <a href="#homeloan">
                  <div className={styles.product}>
                     <div className={styles.icon}>
                        <img src={homeLoanImg} alt={alt('Get a Home Loan - Home Loan Icon')} />
                     </div>

                     <h1 className={styles.caption}>Home Loan</h1>
                  </div>
               </a>

               <a href="#secondloan">
                  <div className={styles.product}>
                     <div className={styles.icon}>
                        <img src={secondLoanImg} alt={alt('Get a Second-Home Loan - Second-Home Loan Icon')} />
                     </div>
                     <h1 className={styles.caption}>Second-Home Loan</h1>
                  </div>
               </a>
               <a href="#reverse">
                  <div className={styles.product}>
                     <div className={styles.icon}>
                        <img src={reverseImg} alt={alt('Get a Reverse Mortgage - Reverse Mortgage Icon')} />
                     </div>
                     <h1 className={styles.caption}>Reverse Mortgage</h1>
                  </div>
               </a>
            </div>
         </section>
      </div>
   );
};

export default CurrentHOPanel;
